.site-footer {
  font-size: 15px;
  line-height: 24px;
}

.footerC {
  overflow: hidden;
  text-align: center;
  font-size: 12px;
}

.footerC:before,
.footerC:after {
  background-color: #fff;
  content: "";
  display: inline-block;
  height: .5px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.socialIcns {
  margin: 0;
  padding: '0';
}

.footerC:before {
  right: 0.5em;
  margin-left: -50%;
}

.footerC:after {
  left: 0.5em;
  margin-right: -50%;
}

/* .site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}

.site-footer hr.small {
  margin: 20px 0;
}

.site-footer h6 {
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
} */

.site-footer a {
  color: #ffffff;
}

.site-footer a:hover {
  color: #EF4E22;
  ;
  text-decoration: none;
}

.footer-links {
  padding-left: 0;
  list-style: none;
}

.footer-links li {
  display: block;
}

.footer-links a {
  color: #EF4E22;
  ;
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #EF4E22;
  ;
  text-decoration: none;
}

.footer-links.inline li {
  display: inline-block;
}

.copyright-text {
  border-top: 1px solid white;
  margin-left: 100px;
}

/* .site-footer .social-icons {
  text-align: right;
}

.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #ffffff;
}



@media (max-width: 991px) {
  .site-footer [class^="col-"] {
    margin-bottom: 1px;
  }
}
@media (max-width: 767px) {
  .site-footer {
    padding-bottom: 0;
  }

  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
} */

/* .social-icons {
  padding-left: 0;
  display:inline-block;
  margin-bottom: 0;
  list-style: none;
} */
.zcolumn {
  float: right;
  width: 24.33%;
  padding: 0px;
}

.ftPic {
  width: 30px;
  margin: 0;
  padding: 0;
}

.footerContainer {
  margin: 5px;
  padding: 5px;
  margin-top: 20px;
  border: 1px solid #b8babb;
  border-bottom: none;
}


.addPropertBtn {
  background-color: #fff !important;
  color: black !important;
  height: 25px !important;
  padding-left: 10px !important;
  width: 100px !important;
  margin-top: 10px !important;
}


.zrow {
  margin-left: 14px;
}

/* Clearfix (clear floats) */
.zrow::after {
  content: "";
  clear: both;
  display: table;
}







.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  cursor: pointer;
}


.fbg {
  background-color: #575757;
  color: #575757;
  width: 500px;
  position: absolute;
  left: -30px;
}