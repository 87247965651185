.btn {
  border: none;
  align-self: center;
}

.hh4 {
  text-align: start;
  font-size: 22px;
  font-weight: 800;
}

.btn111 {
  border: 2px solid #ef4e22;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  padding: 1% 45% 1% 46%;
  background: #ef4e22;
  color: #ffffff;
}

.bt11 {
  border: 2px solid #ef4e22;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 800;
  margin-left: 5px;
  padding: 2px 40px 2px 42px;
  background: #ef4e22;
  color: #ffffff;
}

.bt11:hover {
  background-color: var(--secondary-color) !important;
  color: #ef4e22;
}

.bt22:hover {
  background-color: #ef4e22 !important;
  color: #ffffff;
}

.inputlast {
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 2px;
  padding-top: 2px;
}

.bt22s {
  border: 2px solid #ef4e22;
  border-radius: 50px;
  margin-right: 7px;
  font-size: 16px;
  font-weight: 800;
  padding: 2px 20px 2px 20px;
  background-color: #ffffff;
  color: #ef4e22;
}

.bt22 {
  border: 2px solid #ef4e22;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 800;
  padding: 2px 20px 2px 20px;
  background-color: #ffffff;
  color: #ef4e22;
}

.li {
  color: #ffffff;
  font-size: 16px;
  font-weight: 900;
  text-decoration: none;
}

.headt {
  font-size: 30px;
  font-weight: 900;
  color: #000;
  text-align: center;
}

.last {
  background-color: #57585a;
  color: #ffffff;
}

.lasts {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.25)
    ),
    url(../../Assests/ambasador.jpeg);
  background-position: bottom;
  color: #ffffff;
}

.ltext1 {
  text-decoration: underline;
  font-size: 25px;
  color: #000;
}

.ltext2 {
  font-size: 25px;
  color: #000;
  text-align: end;
  text-decoration: underline;
}

.circle1 {
  width: 35%;
  border-radius: 50%;
  background-color: #ffffff;
  line-height: 0;
  float: center;
  margin-top: 10px;
  margin-left: 50%;
}

.circle1::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.circles2 {
  width: 80%;
  border-radius: 50%;
  background-color: #ffffff;
  line-height: 0;
  position: relative;
  margin: 0px;
}

.circles2::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.circles {
  border-radius: 50%;
  background-color: #ffffff;
  line-height: 0;
  position: relative;
}

.circles::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.textc {
  position: absolute;
  bottom: 50%;
  width: 100%;
  font-weight: 900;
  font-size: 30px;
  text-align: center;
  color: #ef4e22;
}

.he {
  font-size: 22px;
  color: #000;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.h4 {
  font-size: 16px;
  padding-bottom: 5px;
  margin-top: 15px;
  font-weight: 900;
  color: #000;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.inn {
  border-radius: 30px;
  border: 1px solid #e7e8e9;
  font-size: 16px;
  margin: 5px;
}

.innl {
  border-radius: 40px;
  border: 1px solid #e7e8e9;
  font-size: 16px;

  margin: 5px;
}

progress {
  background: crimson;
}

progress {
  color: #ef4e22;
}

progress::-moz-progress-bar {
  background: #ef4e22;
}

progress::-webkit-progress-value {
  background: #93d162;
}

progress::-webkit-progress-bar {
  background: #ef4e22;
}

.blurred-card {
  opacity: 0.5;
  pointer-events: none;
}

.headtitle {
  padding: 5px;
  margin: 0 5% 0 5%;
  border-bottom: 2px solid #999999;

  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.cardse {
  box-sizing: border-box;
}

.cards {
  margin: 0% 0% 6% 0%;
  position: sticky;
}

.thanks {
  color: #000;
}

.progre {
  height: 10px;

  margin-top: 15px;
  padding-top: 0px;
}

.mimage {
  margin-top: 3px;
}

.pproo {
  font-size: 35px;
  font-family: "Gothamroundedbook";
}

.h5 {
  font-size: 35px;
  font-weight: 900;
  padding-top: 8px;
  margin: 0px;
}

.circl {
  margin-top: 30px;
  margin-bottom: 100px;
  float: right;
}

.textcircle {
  margin-top: 10px;
}

.circlfloat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1100px) and (max-width: 1280px) {
  .cards {
    margin: 0% 0% 11% 0%;
    position: sticky;
  }

  .he {
    font-size: 17px;
  }

  .txt2 {
    font-size: 23px;
    margin-top: 5px !important;
  }

  .btn111 {
    font-size: 21px;
    font-weight: 900;
    padding: 1% 45% 1% 45%;
  }

  .circles2 {
    width: 60%;
  }
}

@media (min-width: 451px) and (max-width: 1100px) {
  .textc {
    font-size: 30px;
  }

  .btn111 {
    font-size: 21px;
    font-weight: 900;
    padding: 1% 43% 1% 43%;
  }

  .txt2 {
    font-size: 25px;
    font-weight: bold;
    margin-top: 5px;
  }

  .circle1 {
    width: 50%;
  }
}

.pproo {
  font-size: 1.3rem;
  line-height: 1.3;
  font-family: "Gothamroundedbook";
}

.h5 {
  font-family: "GothamroundedbookBold" !important;
  font-size: 30px;
}

.inputlast {
  padding-bottom: 3px;
  padding-left: 3px;
  padding-right: 2px;
  padding-top: 2px;
}

@media (max-width: 450px) {
  .pproo {
    font-size: 14px;
    font-family: "Gothamroundedbook";
  }

  .h5 {
    font-size: 20px;
    font-family: "GothamroundedbookBold" !important;
  }

  .headt {
    font-size: 22px;
    font-weight: 900;
  }

  .btn111 {
    font-size: 17px;
    padding: 1% 40% 1% 42%;
  }

  .ltext1 {
    font-size: 17px;
  }

  .ltext2 {
    font-size: 17px;
  }

  .textc {
    font-size: 20px;
    font-weight: 100;
  }

  .circle1 {
    margin-left: 50%;
    width: 70px;
    margin-top: 5px;
  }

  .circl {
    margin-bottom: 70px;
  }

  .he {
    font-size: 12px;
    font-weight: 0;
  }

  .headtitle {
    margin: 0 0% 0 0%;
    font-size: 10px;
  }
}

@media (max-width: 393px) {
  .circle1 {
    margin-left: 20%;
    width: 60px;
  }

  .he {
    font-size: 14px;
    font-weight: 0;
    color: #000;
    margin-top: 2px;
  }

  .btn111 {
    font-size: 14px;
    padding: 1% 40% 1% 42%;
  }

  .ltext1 {
    font-size: 14px;
  }

  .ltext2 {
    font-size: 15px;
  }

  .textc {
    font-size: 20px;
    font-weight: 200;
  }

  .headtitle {
    margin: 0 0% 0 0%;
    font-size: 10px;
  }

  /* .circles {
    width: 83%;
  }

  .circles2 {
    width: 83%;

  } */

  .textcircle {
    width: 500%;
  }

  .pproo {
    font-size: 14px;
    width: 270px;
    font-family: "Gothamroundedbook";
  }
}
